import React, {useContext} from "react";
import './style.scss'
import Svg from "./components/Svg";
import {Suspense} from "react";
import Spinner from "./components/Spinner";
import MintContext from "./context";

const Home = React.lazy(() => import('./pages/Home'));

const App = () => {
    const {showMint, setShowMint} = useContext(MintContext);
    return (
        <>
            <Svg/>
            <Suspense fallback={<Spinner/>}>
                <Home showMint={showMint} setShowMint={setShowMint}/>
            </Suspense>
        </>
    )
}

export default App