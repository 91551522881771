import React, {useState} from "react";
import App from "./App";
import {createRoot} from 'react-dom/client';
import MintContext from "./context";


const container = document.getElementById('root');
const root = createRoot(container);

const Main = () => {
    const [showMint, setShowMint] = useState(false)
    return (
        <MintContext.Provider value={{showMint, setShowMint}}>
            <App/>
        </MintContext.Provider>
    )
}

root.render(
    <Main />
);