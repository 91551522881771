import React from "react";

const Svg = () => {
    return (
        <svg width="0" height="0" className="hidden">
            <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 21" id="discord">
                <path
                    d="M19.9061 18.9688C20.6561 19.9375 21.5624 21 21.5624 21C27.0623 20.8125 29.1873 17.1875 29.1873 17.1875C29.1873 9.12507 25.5936 2.62511 25.5936 2.62511C21.9999 -0.0623763 18.5624 0.000123457 18.5624 0.000123457L18.2186 0.406371C22.4686 1.71886 24.4374 3.59385 24.4374 3.59385C21.8436 2.15636 19.3124 1.43762 16.9374 1.18762C15.1249 0.968868 13.4062 1.03137 11.8749 1.21887C11.7187 1.21887 11.5937 1.25012 11.4374 1.28137C10.5624 1.34387 8.43745 1.68761 5.78122 2.84386C4.84372 3.28135 4.31248 3.5626 4.31248 3.5626C4.31248 3.5626 6.37496 1.59386 10.8749 0.281372L10.6249 0.000123457C10.6249 0.000123457 7.18746 -0.0623763 3.59373 2.62511C3.59373 2.62511 0 9.15632 0 17.1875C0 17.1875 2.09374 20.8125 7.62496 21C7.62496 21 8.56245 19.875 9.31245 18.9375C6.12496 18 4.93747 16 4.93747 16C4.93747 16 5.18747 16.1563 5.62497 16.4063C5.65622 16.4375 5.68747 16.4688 5.71872 16.4688C5.81247 16.5313 5.87497 16.5625 5.93747 16.5938C6.56246 16.9375 7.18746 17.2188 7.74996 17.4375C8.7812 17.8438 9.99994 18.25 11.4374 18.5C13.3124 18.8438 15.4999 18.9688 17.9061 18.5313C19.0936 18.3438 20.3124 18 21.5624 17.4688C22.4374 17.1563 23.4061 16.6563 24.4374 16C24.4374 16 23.1874 18.0625 19.9061 18.9688ZM9.93744 14.875C8.5312 14.875 7.37496 13.625 7.37496 12.0938C7.37496 10.5626 8.49995 9.31257 9.93744 9.31257C11.3749 9.31257 12.4999 10.5626 12.4687 12.0938C12.4687 13.625 11.3437 14.875 9.93744 14.875ZM19.0624 14.875C17.6561 14.875 16.4999 13.625 16.4999 12.0938C16.4999 10.5626 17.6249 9.31257 19.0624 9.31257C20.4999 9.31257 21.6249 10.5626 21.6249 12.0938C21.5936 13.625 20.4686 14.875 19.0624 14.875Z"
                />
            </symbol>
            <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 21" id="telegram">
                <path
                    fillRule="evenodd" clipRule="evenodd" d="M1.77544 9.04036C8.57759 6.07678 13.1134 4.123 15.3829 3.17903C21.8629 0.483813 23.2093 0.0156247 24.087 0.000164837C24.28 -0.00323539 24.7116 0.0446012 24.9911 0.271446C25.2272 0.46299 25.2921 0.721738 25.3232 0.903342C25.3543 1.08495 25.393 1.49865 25.3622 1.8219C25.0111 5.51145 23.4917 14.465 22.7187 18.5974C22.3916 20.3459 21.7475 20.9322 21.124 20.9896C19.769 21.1143 18.7401 20.0941 17.4277 19.2338C15.3741 17.8876 14.2139 17.0496 12.2205 15.736C9.91674 14.2179 11.4102 13.3835 12.723 12.0199C13.0666 11.663 19.0368 6.23264 19.1524 5.74002C19.1669 5.67841 19.1803 5.44875 19.0438 5.32749C18.9074 5.20622 18.706 5.24769 18.5607 5.28067C18.3548 5.32742 15.074 7.49585 8.71857 11.786C7.78735 12.4254 6.94388 12.737 6.18816 12.7206C5.35504 12.7026 3.75245 12.2496 2.56109 11.8623C1.09983 11.3873 -0.0615436 11.1362 0.0395861 10.3295C0.0922607 9.90932 0.670879 9.47961 1.77544 9.04036Z"
                />
            </symbol>
            <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 21" id="twitter">
                <path
                    d="M26.0335 2.42308C25.0643 2.90769 24.0951 3.06923 22.9643 3.23077C24.0951 2.58462 24.9028 1.61538 25.2258 0.323077C24.2566 0.96923 23.1258 1.29231 21.8335 1.61538C20.8643 0.646154 19.4105 0 17.9566 0C14.5643 0 11.9797 3.23077 12.7874 6.46154C8.42584 6.3 4.54892 4.2 1.80276 0.969231C0.348916 3.39231 1.15661 6.46154 3.41815 8.07692C2.61045 8.07692 1.80276 7.75385 0.99507 7.43077C0.99507 9.85385 2.77199 12.1154 5.19507 12.7615C4.38738 12.9231 3.57969 13.0846 2.77199 12.9231C3.41815 15.0231 5.35661 16.6385 7.77969 16.6385C5.84122 18.0923 2.93353 18.9 0.187378 18.5769C2.61045 20.0308 5.35661 21 8.2643 21C18.1181 21 23.6105 12.7615 23.2874 5.16923C24.4181 4.52308 25.3874 3.55385 26.0335 2.42308Z"
                />
            </symbol>
            <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 21" id="youtube">
                <path
                    fillRule="evenodd" clipRule="evenodd" d="M28.7241 1.60775C29.1897 2.07336 29.5252 2.65283 29.6972 3.28846C30.6876 7.275 30.4588 13.5712 29.7165 17.7115C29.5445 18.3472 29.209 18.9266 28.7433 19.3923C28.2777 19.8579 27.6982 20.1934 27.0626 20.3654C24.7357 21 15.3703 21 15.3703 21C15.3703 21 6.00493 21 3.67801 20.3654C3.04239 20.1934 2.46291 19.8579 1.99729 19.3923C1.53167 18.9266 1.19616 18.3472 1.02416 17.7115C0.0280073 13.7423 0.301085 7.44231 1.00493 3.30769C1.17693 2.67207 1.51244 2.09259 1.97806 1.62697C2.44368 1.16136 3.02315 0.825841 3.65878 0.653851C5.9857 0.0192261 15.3511 0 15.3511 0C15.3511 0 24.7165 0 27.0434 0.634613C27.679 0.806615 28.2585 1.14213 28.7241 1.60775ZM20.1395 10.5L12.3703 15V5.99999L20.1395 10.5Z"
                />
            </symbol>
            <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" id="close">
                <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"/>
            </symbol>
        </svg>
    )
}

export default Svg;